// @import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

//responsive
$width-ipad: 1280px;
$width-mobile: 768px;

:root {
    --primary: #322c2b;
    --font-family: Arial, Helvetica, sans-serif;

    //defaut layout
    --height-header: 60px;

    --text-color: rgb(22, 24, 35);
    --text-color-secondnary: #ffffff;
    --text-color-link: #222831;
    --background-color: #ffffff;
    --background-color-secondnary: #eeeeee;
    --background-button: #eeeeee;
    --background-button-hightlight: #222831;
    --background-button-hightlight-secondary: #eeeeee;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    text-rendering: optimizespeed;
    overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

.two-lines-truncate {
    display: block;
    white-space: wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-height: calc(2rem * 2);
    line-height: 1rem;
}
hr {
    width: 100%;
    height: 1px;
    background-color: #f1eaea;
}

.control-dots .dot {
    box-shadow: none;
}

.control-dots .dot.selected {
    width: 30px !important;
    border-radius: 8px;
    box-shadow: none;
}
