.wrapper {
    min-width: 80px;
    min-height: 30px;
    color: var(--text-color);
}

.primary {
    background-color: var(--background-button) !important;
    border: 1px solid var(--primary) !important;

    &:hover {
        background: var(--background-button-hightlight) !important;
        color: var(--text-color-secondnary);
    }
}
